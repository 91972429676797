import React, { useEffect, useState } from "react";
import "./OrbixNavbar.css";
// import orbixLogo from "../../assets/orbixLogo.svg";
import orbixLogo from "../../assets/orbixLogo.png";
import flagImg from "../../assets/flagImg.svg";
import { noImage } from "../../assets";
import { useSelector } from "react-redux";
import { routes } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { BASE_URL, USER_LOGIN_DATA } from "../../utils/constants";

export default function OrbixNavbar() {
  const { userData } = useSelector((state) => state.user);
  const img = userData?.image
    ? 
    `${BASE_URL}${userData.image}`
    : noImage;
  const [imgErr, setImgErr] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (
  //     userData &&
  //     window.location.pathname != "/business-role" &&
  //     window.location.pathname != "/hear-from" &&
  //     window.location.pathname != "/welcome"
  //   ) {
  //     navigate(
  //       userData.is_verified
  //         ? userData.is_user_subscribed
  //           ? routes.dashboard
  //           : userData.is_hear_from
  //           ? routes.subscription
  //           : routes.welcome
  //         : "/"
  //     );
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  function handleImgError() {
    setImgErr(true);
  }
  function navigateToProfile() {
    navigate(routes.profile);
  }

  return (
    <div className="navbarMainContainer">
      <div className="navbarContainer">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(routes.dashboard);
          }}
        >
          <img
            src={orbixLogo}
            alt=""
            style={{ width: "95px", height: "40px" }}
          />
          {/* <img src={orbixLogo} alt="" style={{ width: "95px", height: "40px" }} /> */}
        </div>
        <div className="imageMainContainer">
          <img src={flagImg} alt="" />{" "}
          <img
            style={{
              cursor: "pointer",
              borderRadius: "100%",
              overflow: "hidden",
              height: "36px",
              width: "36px",
              border: "1px solid lightgray",
            }}
            onClick={navigateToProfile}
            src={!imgErr && img}
            alt="pp"
            onError={handleImgError}
          />
        </div>
      </div>
    </div>
  );
}
