import React, { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { passwordChangeApiCall } from "../../hooks/userManagment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function Security() {
  const { userData } = useSelector((state) => state.user);
  const user_Id = userData._id;

  const [formData, setFormData] = useState({
    _id: user_Id,
    old_password: "",
    new_password: "",
  });
  const [errors, setErrors] = useState({
    old_password: "",
    new_password: "",
  });
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear error for the current field
    });
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    // Validate current password
    if (!formData.old_password) {
      newErrors.old_password = "Current password is required.";
      isValid = false;
    }

    // Validate new password
    if (!formData.new_password) {
      newErrors.new_password = "New password is required.";
      isValid = false;
    } else if (formData.new_password.length < 6) {
      newErrors.new_password = "Password must be at least 6 characters long.";
      isValid = false;
    } else if (!/[A-Z]/.test(formData.new_password)) {
      newErrors.new_password = "Password must include at least one uppercase letter.";
      isValid = false;
    } else if (!/[0-9]/.test(formData.new_password)) {
      newErrors.new_password = "Password must include at least one number.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsLoading(true);
    passwordChangeApiCall(formData)
      .then(({ data }) => {
        if (data?.error_code === 1) {
          toast.success("Password changed successfully");
          setFormData({
            _id: user_Id,
            old_password: "",
            new_password: "",
          });
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while changing the password.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full h-full p-7">
      <div className="w-3/5 pt-5">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-5 pb-5">
            {/* Current Password */}
            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Current Password:
                <div className="relative">
                  <input
                    placeholder="Input Your Current Password"
                    id="old_password"
                    className={`profile_input ${
                      errors.old_password ? "border-red-500" : ""
                    }`}
                    type={oldPasswordVisible ? "text" : "password"}
                    name="old_password"
                    value={formData.old_password}
                    onChange={handleChange}
                  />
                  <div
                    className="visible_button absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
                  >
                    {oldPasswordVisible ? (
                      <BsEye size={18} />
                    ) : (
                      <BsEyeSlash size={18} />
                    )}
                  </div>
                </div>
                {errors.old_password && (
                  <span className="text-red-500 text-xs">{errors.old_password}</span>
                )}
              </label>
            </div>

            {/* New Password */}
            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                New Password:
                <div className="relative">
                  <input
                    placeholder="Input Your New Password"
                    id="new_password"
                    className={`profile_input ${
                      errors.new_password ? "border-red-500" : ""
                    }`}
                    type={newPasswordVisible ? "text" : "password"}
                    name="new_password"
                    value={formData.new_password}
                    onChange={handleChange}
                  />
                  <div
                    className="visible_button absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  >
                    {newPasswordVisible ? (
                      <BsEye size={18} />
                    ) : (
                      <BsEyeSlash size={18} />
                    )}
                  </div>
                </div>
                {errors.new_password && (
                  <span className="text-red-500 text-xs">{errors.new_password}</span>
                )}
              </label>
            </div>
          </div>

          <div className="w-full flex justify-end">
            <button
              className="security_button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Security;
