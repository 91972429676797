import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bankingDetailPostApiCall, getUserBankDetailCall } from "../../hooks/plan";
import { ErrorCodes } from "../../utils/enums";

function SecondWelcome_WireTransferPage() {
  const { userData } = useSelector((state) => state?.user);

  const [formData, setFormData] = useState({
    user: userData._id,
    _id: 0,
    bank_country_code: "",
    bank_swift_code: "",
    bank_name: "",
    bank_account_number: "",
    bank_account_name: "",
    bank_iban: "",
    user_fullname: "",
    street_address: "",
    country: "PK",
    city: "",
    postal_code: "",
  });

  const [errors, setErrors] = useState({});

  const countries = countryList().getData();

  useEffect(() => {
    if (userData) getWireTransferData();
  }, [userData]);

  function getWireTransferData() {
    getUserBankDetailCall(userData?._id)
      .then(({ data }) => {
        if (data.error_code == 0) {
          debugger;
          setFormData((prevState) => ({
            ...prevState,
            _id: data.result._id,
            bank_country_code: data.result.bank_country_code,
            bank_swift_code: data.result.bank_swift_code,
            bank_name: data.result.bank_name,
            bank_account_number: data.result.bank_account_number,
            bank_account_name: data.result.bank_account_name,
            bank_iban: data.result.bank_iban,
            user_fullname: data.result.user_fullname,
            street_address: data.result.street_address,
            country: data.result.country,
            city: data.result.city,
            postal_code: data.result.postal_code,
          }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const handleCountryChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      country: selectedOption.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      country: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.bank_country_code.trim())
      newErrors.bank_country_code = "Bank country code is required.";
    if (!formData.bank_swift_code.trim()) newErrors.bank_swift_code = "SWIFT code is required.";
    if (!formData.bank_name.trim()) newErrors.bank_name = "Bank name is required.";
    if (!formData.bank_account_number.trim())
      newErrors.bank_account_number = "Account number is required.";
    if (!formData.bank_account_name.trim())
      newErrors.bank_account_name = "Account name is required.";
    if (!formData.bank_iban.trim()) newErrors.bank_iban = "IBAN is required.";
    if (!formData.user_fullname.trim()) newErrors.user_fullname = "Full name is required.";
    if (!formData.street_address.trim()) newErrors.street_address = "Street address is required.";
    if (!formData.country) newErrors.country = "Country is required.";
    if (!formData.city.trim()) newErrors.city = "City is required.";
    if (!formData.postal_code.trim()) newErrors.postal_code = "Postal code is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("formData", formData);
      bankingDetailPostApiCall(formData)
        .then(({ data }) => {
          if (data.error_code === ErrorCodes.success) {
            toast.success(data.message);
            setFormData({
              user: userData._id,
              _id: 0,
              bank_country_code: "",
              bank_swift_code: "",
              bank_name: "",
              bank_account_number: "",
              bank_account_name: "",
              bank_iban: "",
              user_fullname: "",
              street_address: "",
              country: "PK",
              city: "",
              postal_code: "",
            });
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          toast.error("Network Error");
        });
    } else {
      toast.error("Please correct the errors in the form.");
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} hideProgressBar position="bottom-right" />
      <div className="col-span-12 w-screen content-center text-center flex justify-center items-center">
        <div className="w-11/12 pt-14 pl-14">
          <div className="w-full h-auto md:px-7 py-3">
            <div className="shadow-xl bg-white w-full min-h-[85vh] h-auto rounded-2xl flex flex-col items-center">
              <div className="w-full sm:w-[70%] mx-auto p-6">
                <h2 className="text-start text-xl font-semibold mb-4">
                  Beneficiary bank information
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="Bank country code"
                      className={`w-full border p-2 rounded-md ${
                        errors.bank_country_code ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.bank_country_code}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bank_country_code: e.target.value,
                        })
                      }
                    />
                    {errors.bank_country_code && (
                      <p className="text-start text-red-500 text-sm">{errors.bank_country_code}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="SWIFT code"
                      className={`w-full border p-2 rounded-md ${
                        errors.bank_swift_code ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.bank_swift_code}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bank_swift_code: e.target.value,
                        })
                      }
                    />
                    {errors.bank_swift_code && (
                      <p className="text-start text-red-500 text-sm">{errors.bank_swift_code}</p>
                    )}
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      placeholder="Bank name"
                      className={`w-full border p-2 rounded-md ${
                        errors.bank_name ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.bank_name}
                      onChange={(e) => setFormData({ ...formData, bank_name: e.target.value })}
                    />
                    {errors.bank_name && (
                      <p className="text-start text-red-500 text-sm">{errors.bank_name}</p>
                    )}
                  </div>
                </div>

                <h2 className="text-start text-xl font-semibold mb-4">
                  Beneficiary account information
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                  <div>
                    <input
                      type="text"
                      placeholder="Account number"
                      className={`w-full border p-2 rounded-md ${
                        errors.bank_account_number ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.bank_account_number}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bank_account_number: e.target.value,
                        })
                      }
                    />
                    {errors.bank_account_number && (
                      <p className="text-start text-red-500 text-sm">
                        {errors.bank_account_number}
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Account name"
                      className={`w-full border p-2 rounded-md ${
                        errors.bank_account_name ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.bank_account_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bank_account_name: e.target.value,
                        })
                      }
                    />
                    {errors.bank_account_name && (
                      <p className="text-start text-red-500 text-sm">{errors.bank_account_name}</p>
                    )}
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      placeholder="IBAN"
                      className={`w-full border p-2 rounded-md ${
                        errors.bank_iban ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.bank_iban}
                      onChange={(e) => setFormData({ ...formData, bank_iban: e.target.value })}
                    />
                    {errors.bank_iban && (
                      <p className="text-start text-red-500 text-sm">{errors.bank_iban}</p>
                    )}
                  </div>
                </div>

                <h2 className="text-start text-xl font-semibold mb-4">
                  Beneficiary personal information
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      placeholder="Full name"
                      className={`w-full border p-2 rounded-md ${
                        errors.user_fullname ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.user_fullname}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          user_fullname: e.target.value,
                        })
                      }
                    />
                    {errors.user_fullname && (
                      <p className="text-start text-red-500 text-sm">{errors.user_fullname}</p>
                    )}
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      placeholder="Street address"
                      className={`w-full border p-2 rounded-md ${
                        errors.street_address ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.street_address}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          street_address: e.target.value,
                        })
                      }
                    />
                    {errors.street_address && (
                      <p className="text-start text-red-500 text-sm">{errors.street_address}</p>
                    )}
                  </div>
                  <div>
                    <Select
                      options={countries}
                      value={countries.find((country) => country.value === formData.country)}
                      onChange={handleCountryChange}
                      className={`w-full ${errors.country ? "border-red-500" : "border-gray-300"}`}
                      placeholder="Select a country"
                    />
                    {errors.country && (
                      <p className="text-start text-red-500 text-sm">{errors.country}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="City"
                      className={`w-full border p-2 rounded-md ${
                        errors.city ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.city}
                      onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    />
                    {errors.city && (
                      <p className="text-start text-red-500 text-sm">{errors.city}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Postal code"
                      className={`w-full border p-2 rounded-md ${
                        errors.postal_code ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.postal_code}
                      onChange={(e) => setFormData({ ...formData, postal_code: e.target.value })}
                    />
                    {errors.postal_code && (
                      <p className="text-start text-red-500 text-sm">{errors.postal_code}</p>
                    )}
                  </div>
                </div>

                <div className="flex justify-end border-t pt-4">
                  <button
                    onClick={handleSubmit}
                    className="w-full sm:w-auto bg-purple-600 text-white px-6 py-2 rounded-md font-semibold hover:bg-purple-700 transition"
                  >
                    Add account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondWelcome_WireTransferPage;
