import React from 'react'
import chatIcon_1 from "../../assets/Group 1171275092.svg";
import chatIcon_2 from "../../assets/Group 1171275110.svg";

function PromptCards({index, handlePasteFromDiv,title,message}) {
  return (
    <div 
    onClick={() => handlePasteFromDiv(title)}
    className="cursor-pointer flex bg-gray-200  md:min-h-[60px] md:h-auto rounded-lg py-2 md:mr-10  col-span-12 md:col-span-6 items-center ">
    <img className="w-8 relative -left-4" src={(index === 0 || index === 3) ? chatIcon_1 : chatIcon_2} alt="" />
    <div >
      <h4 className="chatBot_main_group_heading">{title}</h4>
      <p
        id={title}
        className="text-gray-400 chatBot_main_group_heading_text pr-2"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  </div>
  )
}

export default PromptCards
