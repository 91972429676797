import axios from "axios";
import { axiosInstance, axiosFormDataInstance } from "../axios-client/axiosClient";

export const signup = async (data) => {
    const api = axiosFormDataInstance();
    return await api.post("api/user/new-user", data);
};

export const login = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/login-user", data);
};

export const generateCode = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/generate-code", data);
};

export const VerifyCode = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/verify-code", data);
};

export const validateCode = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/validate-verification-code", data);
};

export const forgotPasswordResetApiCall = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/forgot-password", data);
};

export const getAnalyticsList = async (parent_id) => {
    let params = {};
    if (parent_id) params.parent_id = parent_id;
    const api = axiosInstance();
    return await api.get("api/lookup/get-drop-list", { params });
};

export const postBusinessRoleList = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user-business-role/post-list", data);
};

export const postHearFromList = async (data) => {
    const api = axiosInstance();
    return await api.post("api/hear-from/post-list", data);
};

export const userValidateEmail = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/validate-email", data);
};

export const getUserWalletDataCall = async (user_plan_id) => {
    let params = {};
    if (user_plan_id) params.user_plan_id = user_plan_id;
    const api = axiosInstance();
    return await api.get("api/wallet/get-user-wallet", {
        params
    });
};

export const contactUsCall = async (data) => {
    const api = axiosInstance();
    return await api.post("api/contact-us/post", data);
};

export const passwordChangeApiCall = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/change-password", data);
}
export const userUpdateApiCall = async (data) => {
    const api = axiosInstance();
    return await api.post("api/user/post", data, {
        headers:{"Content-Type": "multipart/form-data",}
    });
}