import React, { useState } from "react";
import { GoChevronDown } from "react-icons/go";

function Faq_questions({ answer, question }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="col-span-12">
      <div className="faq_Blocks rounded-2xl px-5 py-2.5">
        <div className="w-full flex gap-1 cursor-pointer relative"
          onClick={handleClick}>
          <div className="w-full md:text-[14px] question text-left">{question}</div>
          <div className=" absolute right-0 top-1">
            <GoChevronDown
              id="dropButton"
              className="my-auto h-full text-lg" />
          </div>
        </div>
        {isVisible && <p className="faq_Blocks__innerText faq_light_text pt-2 text-left" dangerouslySetInnerHTML={{ __html: answer}}/>}
      </div>
    </div>
  );
}

export default Faq_questions;
